@import '~rsuite/dist/rsuite.min.css';

a.rs-navbar-brand {
    font-size: 16px;
}

nav.rs-sidenav {
    height: 100%;
}

div.rs-sidenav-toggle {
    margin-top: 0;
}

html, body, #app, #root, #work_area, #app_loading {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#app_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292d33;
    color: #fff;
    font-size: 24px;
}

#work_area {
    display: flex;
    flex-grow: 1;
}

#app main {
    width: 100%;
    height: calc(100vh - 104px);
    overflow-y: auto;
}

body {
    margin: 0;
}

h2.header {
    padding: 25px 0;
    justify-content: center;
    display: flex;
    gap: 10px;
    text-align: center;
    width: 100%;
}

h2.header i {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
}

div.middle-flex {
    width: 60%;
    margin: 0 auto;
}

.rs-btn-icon.btn-tree {
    position: absolute;
    top: 7px;
    right: 7px;
}

.modal-fixed-custom{
    min-height: 350px;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.middle-flex-large {
    width: 90%;
    margin: 0 auto;
}

.profileInfo {
    display: flex;
    font-size: initial;
    padding: 5px 0;
}

.profileInfo b {
    padding-right: 7px;
}

div.lowPadding {
    padding: 5px 0;
}

span.deniedSpan {
    display: flex;
    padding: inherit;
    justify-content: center;
}

.rs-table-cell {
    height: 64px;
    min-height: 64px;
}

.rs-table-cell-content {
    padding: 0;
}

span.rs-tree-node-label,
span.rs-tree-node-label-content {
    width: 100% !important;
}

.rs-modal-footer{
    padding-top: 14px;
}

.rs-modal-body{
    padding-bottom: 0;
}

.rs-table-row.rs-table-row-header{
    min-width: auto !important;
}

.flex_modal_form{
    display: flex
}

.flex_modal_form .rs-form-control{
    display: flex;
    flex-grow: 1;
}

.flex_modal_form .rs-form-control input{
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 15px);
}